<template>
    <div class="error-view">
        <slot/>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'ErrorView',
    created () {
        this.$store.dispatch('sidebar/setSidebarOpened', false);
        document.querySelector('#page-container.sidebar-o').classList.add('p-0');
    },
    beforeDestroy () {
        this.$store.dispatch('sidebar/setSidebarOpened', true);
        document.querySelector('#page-container.sidebar-o').classList.remove('p-0');
    }
}
</script>

<style lang="scss">

</style>
